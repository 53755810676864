import { styled, DataTable } from "@puzzle/ui";

import { DarkBackgroundLoadingBar } from "components/common/LoadingBar";

import { colors } from "ve/theme/colors";
import { S } from "ve";

const MAX_TABLE_SIZE = "300px";

export const Container = styled("div", {
  overflow: "hidden",
});
export const EventTable = styled("div", {
  maxHeight: MAX_TABLE_SIZE,
  borderTop: `1px solid ${colors.mauve680}`,
  paddingTop: "$2",

  table: {
    borderCollapse: "collapse",
  },

  thead: {
    display: "none",
  },

  'tr[role="row"]': {
    border: "none",
  },

  'tr[role="row"]:hover td': {
    backgroundColor: "#303040",
    color: "$gray300",
  },

  td: {
    padding: "2px $1",
    border: "none",
    color: "$gray400",
    fontSize: "13px",
    lineHeight: "20px",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",

    "&:last-child": {
      textOverflow: "initial",
    },
  },

  svg: {
    verticalAlign: "middle",
  },
});
export const Header = styled("div", {
  display: "flex",
  justifyItems: "space-between",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  color: "$gray500",
  fontSize: "13px",
  lineHeight: "20px",
  marginBottom: "$2",
  flexWrap: "wrap",
});
export const SubHeader = styled("div", {
  display: "flex",
  justifyContent: "space-between",
  marginBottom: "$1",
  color: "$gray200",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  padding: "0 $0h 0 $1",
  fontWeight: "$bold",

  "&:hover": {
    backgroundColor: "#303040",
  },

  "& > *:first-child": {
    maxWidth: MAX_TABLE_SIZE,
    overflow: "hidden",
    textOverflow: "ellipsis",
    "& > *:first-child": {
      display: "inline-block",
      color: "$gray400",
      marginRight: "$1",
    },
  },
});
export const Title = styled("div", {
  display: "flex",
  alignItems: "center",
});
export const Total = styled("div", {
  flexShrink: "0",
  color: "$gray200",
});
export const RowLoading = styled(DarkBackgroundLoadingBar, {
  width: "100%",
  height: S["3"],
  marginBottom: S["1"],
});
export const StyledDataTable = styled(DataTable, {
  td: {
    borderBottom: "none !important",
  },

  'tr[data-disabled="true"] td': {
    pointerEvents: "initial",
    cursor: "initial",
    "&, *": {
      color: "inherit ",
    },
  },
}) as unknown as typeof DataTable;
